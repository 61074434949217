/* --------- navbar ----------*/
@media (max-width: 750px) {
    .headerHamburgerMenu {
      display: flex;
      flex-direction: column;
      margin-left: auto;
    }
  
    .helpPanels {
      display: none;
    }
  
    .userProfileFlexDiv {
      display: none;
    }
  
    #headerHamburgerMenuItemsDiv {
      position: absolute;
      top: 65px;
      right: 0px;
      background-color: #ffffff;
      z-index: 1500;
    }
  
    #hamburgerFlex {
      display: flex;
      flex-direction: column;
      font-size: 12px;
    }
  
    .headerHamItem {
      padding: 5px 10px 5px 10px;
      border-top: 1px solid #dbdbdb;
      border-bottom: 1px solid #dbdbdb;
      cursor: pointer;
    }
  
    .headerHamItem:hover {
      background-color: #ededed;
      color: #0c9ed9;
    }
  
    .helpLinkMobile {
      text-decoration: none;
      color: unset;
    }
  
    .helpLinkMobile:hover {
      color: #0c9ed9;
    }
  
    .headerHamItem.borderNone {
      border: none;
    }
  
    .headerHamItemSpan {
      display: block;
      width: 100%;
    }
  }
  
  @media (min-width: 750px) {
    #headerHamburgerMenuItemsDiv {
      display: none;
    }
  }
  
  /* --------- start buttons ---------*/
  @media (max-width: 1040px) {
    body{
      font-size: 1em;
      overflow: hidden;
    }
  
    .rtSubtitle {
      display: none;
    }
  
    .blueIconArea {
      margin-top: -12px;
      padding-left: 12px;
    }
  
    #colliersLogo {
      height: 75px;
    }
  
    .pageContainer {
      height: calc(100% - 65px);
    }
  
    .topBlueBar {
      display: none;
    }
  
    .navBarDiv {
      height: 65px;
    }

    .mapSideButtonsDiv {
      top: 150px;
    }
  }
  
  @media (max-width: 600px) {
    .sideBarBtnTxt {
      font-size: 9px;
    }
  
    .sideBarDiv {
      width: 70px;
    }
  
    .PanelButton-root-10 {
      width: 68px !important;
    }
  
    .mapContainer {
      width: calc(100% - 70px);
    }
  
    .sidePanelStyle {
      left: 80px;
      min-width: unset;
      width: calc(100vw - 130px);
    }
  }
  
  @media (max-width: 350px) {
    body {
      font-size: 0.8em;
    }
  }
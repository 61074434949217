/* ------------ import Google fonts ------------ */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

#root, body, html {
  height: 100%;
  min-height: 540px;
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  margin: 0px;
  box-sizing: border-box;
  color: #4a4a4d;
}

/* ------------ spinner -------------- */
.loader {
  border: 16px solid #ebebee; /* Light grey */
  border-top: 16px solid #0c9ed9; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

*, *::before, *::after {
  box-sizing: inherit;
}

.loaderShow {
  display: block;
}

.loaderShowMain {
  display: block;
  height: 100%;
}

.loaderHide {
  display: none;
}

.panel-loader {
  border: 16px solid #ebebee; /* Light grey */
  border-top: 16px solid #0c9ed9; /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

/* ---------- navbar ------------ */
.navBarDiv {
  color: #25408f;
  height: 95px;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  }
  
.topBlueBar {
  height: 30px;
  width: 100%;
  background-color: #25408f;
}

.blueIconArea {
  height: 65px;
  width: 100px;
  padding-left: 14px;
  padding-top: 10px;
}

#colliersLogo {
  height: 100px;
}
  
.userProfileFlexDiv {
  display: flex;
  flex-direction: column;
}

.feedbackDiv {
  font-size: 12px;
  text-decoration: underline;
}

.helpPanels {
  margin-left:auto;
  padding-top: 8px;
  font-weight: bold;
  padding-right: 30px;
}

.helpPanelItem {
  margin-left: 10px;
  margin-right: 10px;
}

.helpLink {
  text-decoration: none;
  color: #25408f;
}

.helpLink:hover {
  color: #0c9ed9;
}

.headerHamburgerMenu {
  display: none;
}
  
#clickHeaderHamburger {
  margin-right: 15px;
}

#headerHamburgerMenuItemsDiv {
  display: none;
}

/* ---------- mainpage ------------ */
.appDiv {
  height: 100%;
}

.pageContainer {
  height: calc(100% - 95px);
  width: 100%;
}

/* ---------- sidebar ------------ */
.sideBarDiv {
  width: 100px;
  background-color: #ffffff;
  color: #25408f;
  float: left;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
}

.sideBarBtnTxt {
  font-size: 10px;
  color: #25408f;
  font-family: 'Open Sans', Arial, sans-serif;
}

.PanelButton-root-1:hover .sideBarBtnTxt {
  color: #0c9ed9;
}

.MuiButton-label {
  display: flex;
  flex-direction: column;
}

.sidebarMapIdDiv {
  margin-top: auto;
  margin-bottom: 20px;
  padding-left: 10px;
}

/* ---------- mapdiv ------------- */
.mapDiv {
  height: 100%;
  z-index: 100;
}

.mapContainer {
  float: right;
  height: 100%;
  width: calc(100% - 100px);
  z-index: 100
}

.mapClickCursor {
  cursor: crosshair !important;
}

.mapSideButtonsDiv {
  position: absolute;
  top: 180px;
  right: 10px;
  z-index: 100;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid #B9BEBF;
}

.mapSideButtonsDiv2 {
  position: absolute;
  top: 225px;
  right: 10px;
  z-index: 100;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid #B9BEBF;
}

.mapCustomBtn {
  width: 30px;
  height: 30px;
  line-height: 40px;
  text-align: center;
  background-color: #ffffff;
  border-bottom: 1px solid #cccccc;
}

.mapCustomBtnImageH {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-image: url('../src/utils/images/hybridThumbnail.PNG');
  background-size: cover;
}

.mapCustomBtnImageS{
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-image: url('../src/utils/images/streetThumbnail.PNG');
  background-size: cover;
}

.mapCustomBtn:hover {
  background-color: #f4f4f4;
}

.mapTopBtn {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.mapBottomBtn {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

/* ---------- panels ---------- */
.closeStyle {
  cursor: pointer;
  color: #4a4a4d;
}

.panel-title {
  margin: -15px -20px;
  padding: 15px 20px 15px 20px;
  font-size: 1.2em;
  line-height: 1.1;
  letter-spacing: -.02em;
  display: flex;
}

.panel-icon-span {
  padding-right: 10px;
}

.sidePanelStyle {
  min-width: 420px;
  max-height: calc(100% - 100px);
  background-color: #ffffff;
  position: absolute;
  top: 10px;
  left: 110px;
  z-index: 1000;
  overflow: auto;
  padding: 15px 20px;
  color: #4a4a4d;
}

.panel-close {
  margin: 0 auto;
  margin-right: 0;
}

.MuiOutlinedInput-root {
  width: 100%;
  background: #ffffff;
}

.MuiSelect-outlined.MuiSelect-outlined {
  padding-top: 10.5px;
  padding-bottom: 10.5px;
}

.panel-body {
  padding-top: 15px;
  margin-bottom: 14px;
}

.panel-body-small-text {
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.targetLayerLabel {
  width: 32%;
}

.targetLayerSelectDiv {
  width: 68%;
  display: flex;
  align-items: center;
}

.targetLayerOuterDiv {
  display: flex;
  padding: 0px 0px 5px 0px;
  align-items: center;
}

.geocodeBtn {
  font-family: "Open Sans", Arial, sans-serif;
  color: #25408f;
  border: 1px solid #0c9ed9;
  padding: 11px 16px;
  border-radius: 2px;
  cursor: pointer;
  background-color: #ffffff;
  font-weight: bold;
  letter-spacing: .15px;
  font-size: 12px;
  line-height: 1;
}

.geocodeBtn:disabled {
  font-family: "Open Sans", Arial, sans-serif;
  color: #9ea2a2;
  border: 1px solid #cccdd5;
  padding: 11px 16px;
  border-radius: 2px;
  cursor: default;
  background-color: #ffffff;
  font-weight: bold;
  letter-spacing: .15px;
  font-size: 12px;
  line-height: 1;
}

.geocodeBtnSmall {
  color: #25408f;
  border: 1px solid #0c9ed9;
  padding: 5px 8px;
  border-radius: 2px;
  cursor: pointer;
  background-color: #ffffff;
  font-weight: bold;
  letter-spacing: .15px;
  font-size: 9px;
  line-height: 1;
}

.legendPositionBtn {
  color: #25408f;
  border: 1px solid #0c9ed9;
  padding: 2px 2px;
  border-radius: 2px;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: .15px;
  font-size: 9px;
  line-height: 1;
  margin-left: 4px;
}

.legendPositionBtnActive {
  background-color: #b7e4f4;
}

.legendPositionBtnInactive {
  background-color: #ffffff;
}

.height-21 { 
  height: 21px;
}

.pushLeft {
  margin-left: auto;
  margin-right: 10px;
}

.addPtsBtnsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0px 0px 0px;
}

.addPtsBtnDiv {
  padding: 10px 0px 10px 0px;
}

.geocodeDiv {
  margin-top: 10px;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.flexRight {
  display: flex;
  justify-content: flex-end;
}

.padding-6b {
  padding-bottom: 6px;
}

.padding-t10 {
  padding-top: 10px;
}

.padding-t15 {
  padding-top: 15px;
}

.padding-l10 {
  padding-left: 10px;
}

.padding-l20 {
  padding-left: 20px;
}

.paddingT10 {
  padding-top: 10px;
}

.padding-12b {
  padding-bottom: 12px;
}

.padding-5t {
  padding-top: 5px;
}

.margin-t15 {
  margin-top: 15px;
}

.margin-t20 {
  margin-top: 20px;
}

.padding-6 {
  padding: 6px;
}

.textAreaDiv {
  padding-bottom: 6px;
}

li.search {
  padding: 6px 12px 6px 12px;
  cursor: pointer;
  background-color: #ffffff;
}

li.search:hover {
  background-color: #f3f5f7;
}

.fileUploadInput {
  display: none;
}

.errorMsgDiv {
  color: #ed1b34;
  padding-top: 5px;
}

.errorText {
  color: #ed1b34;
}

.buttonRow {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 5px;
}

.resultsTopDiv {
  display: flex;
  align-items: center;
}

.resultTxtSpan {
  color: #0c9ed9;
  font-weight: bold;
}

.resultInstructions {
  font-size: 12px;
}

.resultTableDiv {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

.goToRecordDiv {
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}

.goToRecordInnerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.legendRowDiv {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: -10px;
  margin-left: -5px;
}

.legendSVGIconDiv {
  transform: scale(0.75);
}

.legendRowTextDiv {
  margin-top: -4px;
}

.circle {
  width: 36px;
  height: 36px;
  background: #25408f;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 36px;
}

.circleIdDiv {
  display: flex;
}

.circleRecordId {
  color: #ffffff;
  font-weight: 600;
}

.inputAddrDiv {
  padding-left: 10px;
  width: 100%;
}

.inputAddrText {
  font-size: 12px;
  color: #929294;
}

.currAddrText {
  font-size: 14px;
  font-weight: 600;
}

.matchAddrDiv {
  font-size: 12px;
}

.scoreTypeDiv {
  display: flex;
}

.scoreTypeText {
  font-size: 12px;
  color: #4a4a4d;
}

.matchDivOuter {
  line-height: 1;
  cursor: pointer;
  background: #ffffff;
  /* border: 1px solid rgb(214, 215, 221); */
  border-radius: 2px;
  padding: 5px;
  margin-bottom: 5px;
}

.grayBorder {
  border: 1px solid rgb(214, 215, 221);
}

.blueBorder {
  border: 2px solid #0c9ed9;
}

.matchSection {
  /* background-color: #ececec; */
  padding: 5px;
}

.downloadMenuDiv {
  display: flex;
  flex-direction: column;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
}

.infoLink {
  text-decoration: none;
  color: #25408f;
  font-size: 14px;
}

.infoLink:hover {
  color: #0c9ed9;
}

.infoPanelUL {
  margin: 0px;
  list-style-type: circle;
}

.boldSpan {
  font-weight: 700;
}

.infoZIndex {
  z-index: 2000;
}

.ts-tableBody {
  display: flex;
  flex-direction: column;
  font-size: 10px;
  padding-top: 10px;
  color: #25408f;
}

.ts-headerRow {
  display: flex;
}

.ts-dataRow {
  display: flex;
}

.ts-row1 {
  width: 32%;
  padding: 2px;
}

.ts-row2 {
  width: 18%;
  padding: 2px;
}

.ts-row3 {
  width: 20%;
  padding: 2px;
}

.ts-row4 {
  width: 15%;
  padding: 2px;
}

.ts-row5 {
  width: 15%;
  padding: 2px;
}

.ts-border {
  border-top: 1px solid #cccdd5;
  border-bottom: 1px solid #cccdd5;
  border-left: 1px solid #cccdd5;
}

.ts-border2 {
  border-bottom: 1px solid #cccdd5;
  border-left: 1px solid #cccdd5;
}

.ts-border3 {
  border-top: 1px solid #cccdd5;
  border-bottom: 1px solid #cccdd5;
  border-left: 1px solid #cccdd5;
  border-right: 1px solid #cccdd5;
}

.ts-border4 {
  border-bottom: 1px solid #cccdd5;
  border-left: 1px solid #cccdd5;
  border-right: 1px solid #cccdd5;
}

.ts-faq-text {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #0c9ed9;
}